import styled from "styled-components";
import { Pagination, Typography } from "antd";
const { Paragraph } = Typography;

export const PaginationStyled = styled(Pagination)`
    display: flex;
    justify-content: center;
    align-items: center;

    .ant-pagination-item {
        border: none;
        width: 10px;
        height: 10px;
        min-width: 10px;
        margin: 0 10px;
    }

    .ant-pagination-item-active {
        border: none;
        min-width: 10px;
        height: 10px;
        color: #2c53af;
        margin: 0 10px;
    }

    .ant-pagination-next {
        margin-inline-start: 8px;
    }

    .ant-pagination-item-active .custom-pagination-dot {
        border: none;
        background-color: #2c53af;
    }
`;

export const MobilePaginationStyled = styled(Pagination)`
    display: flex;
    justify-content: center;
    align-items: center;

    .ant-pagination-item {
        border: none;
        width: 10px;
        height: 10px;
        min-width: 10px;
        margin: 0 10px;
    }

    .ant-pagination-item-active {
        border: none;
        min-width: 10px;
        height: 10px;
        color: #2c53af;
        margin: 0 10px;
    }

    .ant-pagination-next {
        margin-inline-start: 8px;
    }

    .ant-pagination-item-active .custom-pagination-dot {
        border: none;
        background-color: #2c53af;
    }
`;

export const ParagraphTitleStyled = styled(Paragraph)`
    && {
        width: ${(props) => props.width ?? "593px"};
        height: ${(props) => props.height ?? "30px"};
        margin-bottom: 0;
        font-size: inherit;
        font-family: inherit;
        line-height: inherit;
        font-weight: inherit;
        color: inherit;
        white-space: inherit;
        padding: 0 0 1px 0;
        overflow: hidden;
    }
    &:hover {
        color: #2c53af;
        text-decoration: underline;
    }
`;

export const ParagraphDescriptionStyled = styled(Paragraph)`
    && {
        width: ${(props) => props.width};
        height: ${(props) => props.height};
        margin-bottom: 0;
        font-size: inherit;
        font-family: inherit;
        line-height: inherit;
        font-weight: inherit;
        color: inherit;
        white-space: inherit;
        padding: 0 0 1px 0;
        overflow: hidden;
        text-overflow: ellipsis;
    }
`;

export const HeroLanding = styled.div`
    background-image: url(./img/${(props) => props.bgurl});
    background-position: 50% 50%;
    background-size: cover;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    position: relative;

    @media (max-width: 1366px) {
        height: 768px;
    }

    @media screen and (min-width: 1367px) {
        height: 100vh;
    }
`;

export const MediaDisplay = styled.div`
    display: flex;
    flex-direction: ${(props) => props.direction ?? ""};
    justify-content: ${(props) => props.justify ?? ""};
    align-items: ${(props) => props.align ?? ""};
    padding: ${(props) => props.padding ?? ""};
    margin: ${(props) => props.margin ?? ""};
    width: ${(props) => props.width ?? ""};
    height: ${(props) => props.height ?? ""};
`;

export const MediaHeroWrapper = styled.div`
    -webkit-text-stroke: ${(props) => props.stroke};
    color: ${(props) => props.color};
    font-family: ${(props) => (props.languagecode = "'Barlow', Helvetica")};
    font-size: ${(props) => props.fsize};
    font-weight: ${(props) => props.fweight};
    letter-spacing: 0;
    line-height: ${(props) => props.linehg};
    text-align: center;
    text-shadow: ${(props) => props.shadow};
    white-space: ${(props) => props.wspace ?? "nowrap"};
    padding: ${(props) => props.padding};

    @media (min-width: 768px) and (max-width: 1279px) {
        font-size: 69px;
        line-height: 79px;
    }
`;

export const MediaSection = styled.div`
    background-color: ${(props) => props.bgcolor ?? ""};
    position: relative;
    width: 100%;
    display: flex;
    flex-direction: ${(props) => props.direction ?? ""};
    justify-content: ${(props) => props.justify ?? ""};
    align-items: ${(props) => props.align ?? ""};
    height: ${(props) => props.height ?? ""};
    padding: ${(props) => props.padding ?? ""};
`;

export const MediaDivWrapper = styled.div`
    display: ${(props) => props.display ?? ""};
    flex-direction: ${(props) => props.fdirection ?? ""};
    width: ${(props) => props.width ?? ""};
    height: ${(props) => props.height ?? ""};
    margin: ${(props) => props.margin ?? ""};
    padding: ${(props) => props.padding ?? ""};
    position: ${(props) => props.position ?? ""};
    background-color: ${(props) => props.bgcolor ?? ""};
    cursor: pointer;
`;

export const MediaParagraphs = styled.div`
    width: ${(props) => props.width ?? ""};
    height: ${(props) => props.height ?? ""};
    color: ${(props) => props.color ?? "#353535"};
    font-family: ${(props) => (props.languagecode === "jp" ? "'HiraKaku', sans-serif" : props.font ?? "'Barlow', Helvetica")};
    font-size: ${(props) => props.fsize};
    font-weight: ${(props) => props.fweight ?? "700"};
    letter-spacing: 0;
    line-height: ${(props) => props.linehg};
    text-align: ${(props) => props.align ?? "center"};
    text-shadow: ${(props) => props.shadow};
    white-space: ${(props) => props.wspace ?? "nowrap"};
    padding: ${(props) => props.padding};
    overflow: ${(props) => props.overflow};
    overflow-y: ${(props) => props.overflowy};
    cursor: ${(props) => props.cursor};

    @media (min-width: 768px) and (max-width: 1279px) {
        font-size: ${(props) => props.tablet ?? "21px"};
        line-height: ${(props) => props.tablet ?? "31.5px"};
        min-height: ${(props) => props.minheight ?? ""};
    }
`;

export const MediaImage = styled.img`
    width: ${(props) => props.width ?? ""};
    height: ${(props) => props.height ?? ""};
    object-fit: ${(props) => props.fit ?? ""};

    @media (min-width: 768px) and (max-width: 1279px) {
        min-height: ${(props) => props.minheight ?? "220px"};
    }
    @media (max-width: 480px) {
        min-height: ${(props) => props.minheight ?? "103px"};
    }
`;

export const Retangle = styled.div`
    background-color: #cbe7f2;
    width: 50px;
    height: 10px;
    margin: ${(props) => props.margin ?? "13px 10px 0 0"};
    text-align: ${(props) => props.align};
`;

export const DotWrapper = styled.div`
    width: 10px;
    height: 10px;
    border: 1px solid;
    border-radius: 10px;
`;

export const FirstLastWrapper = styled.a`
    color: #353535;
    font-size: 14px;
    font-family: ${(props) => (props.languagecode === "jp" ? "'HiraKaku', sans-serif" : "'Segoe UI', Helvetica")};
    font-weight: 700;
    letter-spacing: 0;
`;

export const MediaReadMoreWrapper = styled.div`
    width: ${(props) => props.width ?? ""};
    height: ${(props) => props.height ?? ""};
    margin: ${(props) => props.margin ?? ""};
    padding: ${(props) => props.padding ?? ""};
    position: ${(props) => props.position ?? ""};
    bottom: ${(props) => props.bottom ?? ""};
    right: ${(props) => props.right ?? ""};
    left: ${(props) => props.left ?? ""};
    cursor: pointer;
`;

export const MediaPaginationWrapper = styled.div`
    width: ${(props) => props.width ?? ""};
    height: ${(props) => props.height ?? ""};
    margin: ${(props) => props.margin ?? ""};
    padding: ${(props) => props.padding ?? ""};
    position: ${(props) => props.position ?? ""};
    bottom: ${(props) => props.bottom ?? ""};
    right: ${(props) => props.right ?? ""};
    left: ${(props) => props.left ?? ""};
    cursor: pointer;
`;

export const MediaReadMore = styled.div`
    width: 75px;
    height: 14px;
    font-size: 9px;
    color: #f4f4f4;
    font-family: ${(props) => (props.languagecode === "jp" ? "'HiraKaku', sans-serif" : "'Barlow', Helvetica")};
    background-color: #5e5e5e;
    box-shadow: 0 1px 2px #353535;
    text-align: center;
    line-height: 16px;
    letter-spacing: 0;
    font-weight: 600;
`;

export const CloseButtonWrapper = styled.div`
    position: absolute;
    bottom: 0;
    right: ${(props) => props.right ?? "0"};
    left: ${(props) => props.left ?? ""};
    transform: translateX(-50%);
    z-index: 9999;
    text-align: center;
    background-color: #353535;
    box-shadow: 0px 2px 4px #353535;
    margin: 0 0 12px 0;
    cursor: pointer;
`;

export const ButtonCloseSlider = styled.div`
    padding: 3px 0 0 0;
    width: 130px;
    height: 40px;
    box-shadow: 0px 2px 4px #353535;
    background-color: #858585;
    text-align: center;
    font-size: 21px;
    font-weight: 600;
    font-family: "Barlow, Helvetica";
    letter-spacing: 0px;
    color: #f4f4f4;
    opacity: 1;
`;
