import React, { useRef, useState, useEffect } from "react";
import PropTypes from "prop-types";
import ContactUsButtonComponent from "../../../components/ContactUsButton";
import parse from "html-react-parser";
import { keyFeatures } from "../Data/key-features.js";
import { animated } from "@react-spring/web";
import { TextAnimation, TextAnimationOnClick } from "../../../breakpoints";
import { useInView } from "react-intersection-observer";
import { Link } from "react-router-dom";
import { FadeInOut, HoverImageZoom, TheBuildingSectionWrapper, TheBuildingContent, TheBuildingJustifyContent, TheBuildingParagraphs, TheBuildingGridIcon, TheBuildingImage, TheBuildingDisplayAlignItems, TheBuildingDisplay } from "../../../style/TheBuildingScreen";
import { useTranslation } from "react-i18next";
import ReactGA from "react-ga4";

const Feature = ({ kf, handleHover, handleLeave }) => {
    const { key, title, description, image } = kf;
    const descriptionSpring = TextAnimationOnClick(key);
    const imageSpring = TextAnimationOnClick(key);
    const { t, i18n } = useTranslation();
    const currentLanguageCode = i18n.language;

    return (
        <FadeInOut key={key}>
            <TheBuildingDisplay direction={"row"} width={"100%"} height={"auto"} onMouseEnter={handleHover} onMouseLeave={handleLeave}>
                <TheBuildingDisplay margin={"0 30px 0 82px"} direction={"column"} width={"40%"} justify={"start"} align={"flex-start"}>
                    <TheBuildingParagraphs languagecode={currentLanguageCode} color={"#2C53AF"} font={"'Barlow', Helvetica"} align={"left"} fsize={currentLanguageCode === "jp" ? "25px" : "27px"} linehg={currentLanguageCode === "jp" ? "28px" : "36px"} fweight={"600"} as={animated.div} style={descriptionSpring}>
                        {parse(t(title))}
                    </TheBuildingParagraphs>

                    <TheBuildingParagraphs languagecode={currentLanguageCode} padding={"25px 0 0 0"} last="left" align={"justify"} fweight={"400"} fsize={currentLanguageCode === "jp" ? "13px" : "16px"} linehg={currentLanguageCode === "jp" ? "24px" : "24px"} as={animated.div} style={descriptionSpring}>
                        {parse(t(description))}
                    </TheBuildingParagraphs>
                </TheBuildingDisplay>
                <TheBuildingDisplay width={"60%"} as={animated.div} style={imageSpring}>
                    <TheBuildingImage height={"500px"} width={"779px"} border={"1px solid #535353"} src={image} />
                </TheBuildingDisplay>
            </TheBuildingDisplay>
        </FadeInOut>
    );
};

const KeyFeatureIconComponent = ({ kf, className, tabKey, changeTab, handleHover, handleLeave }) => {
    const { icon } = kf;
    const iconImage = tabKey === kf.key ? icon.activated : icon.normal;
    const colorActive = tabKey === kf.key ? "#16f0df" : "#2c53af";
    const sizeIcon = kf.key === "evelator" || kf.key === "panorama" ? "60px" : kf.key === "airfilter" || kf.key === "floor" ? "80px" : "70px";
    const { t, i18n } = useTranslation();
    const currentLanguageCode = i18n.language;

    return (
        <HoverImageZoom>
            <TheBuildingDisplayAlignItems
                direction={"column"}
                onClick={() => {
                    changeTab(kf.key);
                }}
                onKeyDown={() => changeTab(kf.key)}
                onMouseEnter={handleHover}
                onMouseLeave={handleLeave}
            >
                <TheBuildingImage fit={"contain"} width={sizeIcon} height={"80px"} className={`key-feature-icon ${className ?? ""}`} alt={iconImage} src={iconImage} />
                <TheBuildingJustifyContent align={"center"} width={""}>
                    <TheBuildingParagraphs languagecode={currentLanguageCode} padding={"10px 0 0 0"} color={colorActive} fsize={currentLanguageCode === "jp" ? "12px" : "15px"} linehg={currentLanguageCode === "jp" ? "24px" : "24px"} fweight={"600"} cursor={"pointer"}>
                        {parse(t(icon.title))}
                    </TheBuildingParagraphs>
                </TheBuildingJustifyContent>
            </TheBuildingDisplayAlignItems>
        </HoverImageZoom>
    );
};
const KeyFeaturesSection = ({ onHeightChange }) => {
    const sectionRef = useRef(null);
    const [tabKey, setTabKey] = useState("ceilingheight");
    const [onMouseHover, setOnMouseHover] = useState(false);
    const [viewRef, inViewRef] = useInView();
    const descriptionSpring = TextAnimation(inViewRef);
    const [currentKey, setCurrentKey] = useState(null);
    const { t, i18n } = useTranslation();
    const currentLanguageCode = i18n.language;

    const updateCurrent = () => {
        const newTabKeyIndex = keyFeatures.findIndex(({ key }) => key === tabKey);
        const nextTabKeyIndex = (newTabKeyIndex + 1) % keyFeatures.length;
        const nextTabKey = keyFeatures[nextTabKeyIndex].key;
        setTabKey(nextTabKey);
    };

    const startInterval = () => {
        setOnMouseHover(false);
    };

    const stopInterval = () => {
        setOnMouseHover(true);
    };

    const changeTab = (index) => {
        ReactGA.event({
            category: "Click",
            action: "Click",
            label: `Key Feature selected icon ${index}`
        });
        setTabKey(index);
    };

    useEffect(() => {
        const kf = keyFeatures.find(({ key }) => key === tabKey);
        if (kf) {
            setCurrentKey(kf);
        }

        if (!onMouseHover && inViewRef) {
            const intervalId = setInterval(updateCurrent, 2000);
            return () => {
                clearInterval(intervalId);
            };
        }
    }, [tabKey, onMouseHover, inViewRef]);

    useEffect(() => {
        const sectionHeight = sectionRef.current ? sectionRef.current.clientHeight : 0;
        onHeightChange((prevHeight) => prevHeight + sectionHeight);
    }, [onHeightChange]);

    useEffect(() => {
        i18n.changeLanguage(currentLanguageCode);
    }, []);

    const KeyFeaturesSelectionPanel = () => {
        let kfs = keyFeatures.map((item, index) => {
            return <KeyFeatureIconComponent handleHover={stopInterval} handleLeave={startInterval} key={index} kf={item} changeTab={changeTab} tabKey={tabKey} />;
        });
        return <TheBuildingGridIcon padding={"40px 0"}>{kfs}</TheBuildingGridIcon>;
    };
    return (
        <TheBuildingSectionWrapper id="key-features" ref={sectionRef}>
            <TheBuildingContent height={"316px"} align={"center"} ref={viewRef}>
                <TheBuildingParagraphs languagecode={currentLanguageCode} font={"'Barlow', Helvetica"} padding={"97px 0 30px 0"} fsize={"37px"} linehg={"47px"} as={animated.div} style={descriptionSpring}>
                    {t("KEY FEATURES")}
                </TheBuildingParagraphs>
                <TheBuildingJustifyContent direction={"column"} width={"1000px"} as={animated.div} style={descriptionSpring}>
                    <TheBuildingParagraphs align="justify" last="left" languagecode={currentLanguageCode} fsize={"16px"} linehg={"24px"} fweight={"400"}>
                        {t("KEY FEATURES DESCRIPTION")}
                    </TheBuildingParagraphs>{" "}
                    <TheBuildingJustifyContent padding={"30px 0px 0px 0px"}>
                        <Link to="/contact">
                            <ContactUsButtonComponent text={t("CONTACT US")} />
                        </Link>
                    </TheBuildingJustifyContent>
                </TheBuildingJustifyContent>
            </TheBuildingContent>
            <TheBuildingContent height={currentLanguageCode === "jp" ? "845px" : "790px"} align={"center"}>
                <TheBuildingDisplay align={"center"}>
                    <KeyFeaturesSelectionPanel />
                    {currentKey && <Feature kf={currentKey} handleHover={stopInterval} handleLeave={startInterval} />}
                </TheBuildingDisplay>
            </TheBuildingContent>
        </TheBuildingSectionWrapper>
    );
};

KeyFeaturesSection.propTypes = {
    onHeightChange: PropTypes.func
};

Feature.propTypes = {
    kf: PropTypes.object,
    handleHover: PropTypes.func,
    handleLeave: PropTypes.func
};

KeyFeatureIconComponent.propTypes = {
    kf: PropTypes.object,
    className: PropTypes.string,
    tabKey: PropTypes.string,
    changeTab: PropTypes.func,
    handleHover: PropTypes.func,
    handleLeave: PropTypes.func
};

export default KeyFeaturesSection;
