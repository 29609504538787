import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import parse from "html-react-parser";
import { MediaDivWrapper, MediaDisplay, MediaImage, MediaParagraphs, Retangle, ParagraphTitleStyled, ParagraphDescriptionStyled, MediaReadMoreWrapper, MediaReadMore } from "../../../style/MediaScreen";

const ellipsTitle = {
    rows: 1,
    expandable: false
};

const ellipsDescription = {
    rows: 4,
    expandable: false
};

const ellipsTitleMobile = {
    rows: 2,
    expandable: false
};

const ellipsDescriptionMobile = {
    rows: 3,
    expandable: false
};

const PostNew = ({ data, isMobile = false, openModal }) => {
    if (!data) {
        return null;
    }

    const { t, i18n } = useTranslation();
    const currentLanguageCode = i18n.language;
    useEffect(() => {
        i18n.changeLanguage(currentLanguageCode);
    }, []);

    const { imageUrl, title, postDated, summary } = data;
    if (isMobile) {
        return (
            <MediaDivWrapper width={"100%"}>
                <MediaDivWrapper width={"100%"} padding={"20px 0px"}>
                    <MediaImage src={`${process.env.REACT_APP_CMS}${imageUrl}`} width={"100%"} height={"100%"} fit={"cover"} onClick={() => openModal(data)} />
                </MediaDivWrapper>
                <MediaDisplay width={"100%"} direction={"column"}>
                    <Retangle margin={"0 0 10px 0"} />
                    <MediaParagraphs languagecode={currentLanguageCode} font={"'Segoe UI', Helvetica"} fsize={"20px"} align={"left"} wspace={""} linehg={"20px"} cursor={"pointer"} onClick={() => openModal(data)}>
                        <ParagraphTitleStyled width={""} height={"auto"} ellipsis={ellipsTitleMobile}>
                            {parse(title)}
                        </ParagraphTitleStyled>
                    </MediaParagraphs>
                    <MediaParagraphs color={"#2C53AF"} languagecode={currentLanguageCode} font={"'Segoe UI', Helvetica"} fsize={"20px"} align={"right"} linehg={"20px"} cursor={"pointer"} onClick={() => openModal(data)}>
                        {parse(postDated)}
                    </MediaParagraphs>
                </MediaDisplay>
                <MediaDivWrapper width={"100%"} height={"auto"} padding={"9px 0 0 0"} onClick={() => openModal(data)}>
                    <MediaParagraphs overflow={"hidden"} languagecode={currentLanguageCode} font={"'Segoe UI', Helvetica"} fsize={"16px"} align={"justify"} linehg={"21px"} fweight={"400"} wspace={""}>
                        <ParagraphDescriptionStyled ellipsis={ellipsDescriptionMobile}>{parse(summary)}</ParagraphDescriptionStyled>
                    </MediaParagraphs>
                </MediaDivWrapper>
                <MediaReadMoreWrapper width={"100%"} height={"auto"} position={"absolute"} bottom={"-20px"} right={"0"} onClick={() => openModal(data)}>
                    <MediaDisplay justify={"end"}>
                        <MediaReadMore languagecode={currentLanguageCode}>{t("READ MORE")}</MediaReadMore>
                    </MediaDisplay>
                </MediaReadMoreWrapper>
            </MediaDivWrapper>
        );
    } else {
        return (
            <MediaDivWrapper width={"100%"}>
                <MediaDivWrapper width={"100%"}>
                    <MediaImage src={`${process.env.REACT_APP_CMS}${imageUrl}`} width={"100%"} height={"398px"} fit={"contain"} onClick={() => openModal(data)} />
                </MediaDivWrapper>
                <MediaDisplay direction={"row"}>
                    <Retangle />
                    <MediaParagraphs languagecode={currentLanguageCode} font={"'Segoe UI', Helvetica"} fsize={"22px"} align={"left"} linehg={"22px"} cursor={"pointer"} onClick={() => openModal(data)}>
                        <ParagraphTitleStyled ellipsis={ellipsTitle}>{parse(title)}</ParagraphTitleStyled>
                    </MediaParagraphs>
                    <MediaParagraphs color={"#2C53AF"} languagecode={currentLanguageCode} font={"'Segoe UI', Helvetica"} fsize={"22px"} align={"left"} linehg={"22px"} cursor={"pointer"} onClick={() => openModal(data)}>
                        {parse(postDated)}
                    </MediaParagraphs>
                </MediaDisplay>
                <MediaDivWrapper width={"100%"} height={"auto"} padding={"9px 0 0 0"} onClick={() => openModal(data)}>
                    <MediaParagraphs overflow={"hidden"} languagecode={currentLanguageCode} font={"'Segoe UI', Helvetica"} fsize={"16px"} align={"left"} linehg={"21px"} fweight={"400"} wspace={""}>
                        <ParagraphDescriptionStyled ellipsis={ellipsDescription}>{parse(summary)}</ParagraphDescriptionStyled>
                    </MediaParagraphs>
                </MediaDivWrapper>
                <MediaReadMoreWrapper width={"100%"} height={"auto"} position={"absolute"} bottom={"0"} right={"11px"}>
                    <MediaDisplay justify={"end"}>
                        <MediaReadMore languagecode={currentLanguageCode} onClick={() => openModal(data)}>
                            {t("READ MORE")}
                        </MediaReadMore>
                    </MediaDisplay>
                </MediaReadMoreWrapper>
            </MediaDivWrapper>
        );
    }
};

PostNew.propTypes = {
    openModal: PropTypes.func,
    isMobile: PropTypes.bool,
    data: PropTypes.object
};

export default PostNew;
