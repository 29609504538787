import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { MediaSection, MediaDivWrapper, MediaParagraphs, MediaDisplay, DotWrapper, FirstLastWrapper, MobilePaginationStyled, MediaPaginationWrapper } from "../../../style/MediaScreen";
import { Modal as MediaModal } from "antd";
import PostNew from "../Data/PostNew";
import PostPastNew from "../Data/PostPastNew";
import PostModal from "../Data/PostModal";
import { useTranslation } from "react-i18next";

const NewsMobile = ({ datas }) => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [postData, setPostData] = useState(null);
    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 5;
    const { t, i18n } = useTranslation();
    const currentLanguageCode = i18n.language;

    useEffect(() => {
        i18n.changeLanguage(currentLanguageCode);
    }, []);

    const openModal = (data) => {
        setIsModalOpen(true);
        setPostData(data);
    };

    const closeModal = () => {
        setIsModalOpen(false);
    };

    const handlePageChange = (page) => {
        setCurrentPage(page);
    };

    const startIndex = (currentPage - 1) * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;
    let dataLists;
    let datasWithoutFirstElement;
    if (datas) {
        datasWithoutFirstElement = datas.slice(1);
        dataLists = datasWithoutFirstElement.slice(startIndex, endIndex);
    } else {
        dataLists = [];
    }
    const itemRender = (_, type, originalElement) => {
        if (type === "page") {
            return <DotWrapper className="custom-pagination-dot"></DotWrapper>;
        }
        if (type === "prev") {
            return <FirstLastWrapper languagecode={currentLanguageCode}>{t("PREV")}</FirstLastWrapper>;
        }
        if (type === "next") {
            return <FirstLastWrapper languagecode={currentLanguageCode}>{t("NEXT")}</FirstLastWrapper>;
        }
        return originalElement;
    };

    return (
        <MediaSection id="news" direction={"column"} justify={"center"}>
            <MediaDivWrapper width={"100vw"} padding={"30px 20px"}>
                <MediaDisplay direction={"column"} justify={"center"}>
                    {/* News */}
                    <MediaDivWrapper width={"100%"} position={"relative"}>
                        <MediaParagraphs languagecode={currentLanguageCode} tablet={"50px"} fsize={"32px"} align={"center"} linehg={"32px"}>
                            {t("NEWS")}
                        </MediaParagraphs>
                        <PostNew data={datas ? datas[0] : null} isMobile={true} openModal={openModal} />
                    </MediaDivWrapper>
                </MediaDisplay>
            </MediaDivWrapper>
            {/* Past News */}
            {dataLists && dataLists.length > 0 && (
                <MediaDivWrapper width={"100vw"} height={"auto"} position={"relative"} padding={"0 20px 50px 20px"} bgcolor={"#F4F4F4"}>
                    <MediaParagraphs languagecode={currentLanguageCode} tablet={"30px"} fsize={"22px"} align={"center"} linehg={"22px"} padding={"20px 0 0 0"}>
                        {t("PAST NEWS")}
                    </MediaParagraphs>
                    <MediaDivWrapper width={"100%"} padding={"20px 0"}>
                        {dataLists.map((item) => (
                            <PostPastNew key={item.id} data={item} isMobile={true} openModal={openModal} />
                        ))}
                    </MediaDivWrapper>
                    {datasWithoutFirstElement && datasWithoutFirstElement.length > 0 && datasWithoutFirstElement.length > itemsPerPage && (
                        <MediaPaginationWrapper position={"absolute"} bottom={"20px"} left={"0"} right={"0"}>
                            <MobilePaginationStyled className="custom-pagination" current={currentPage} onChange={handlePageChange} total={datasWithoutFirstElement.length} pageSize={itemsPerPage} showSizeChanger={false} itemRender={itemRender} />
                        </MediaPaginationWrapper>
                    )}
                </MediaDivWrapper>
            )}
            <MediaModal open={isModalOpen} onCancel={closeModal} footer={null} width={"80vw"} height={"auto"} padding={"10px"} zIndex={99999999999} centered className="fullscreen-modal" closeIcon={true}>
                <PostModal data={postData || {}} isMobile={true} />
            </MediaModal>
        </MediaSection>
    );
};

NewsMobile.propTypes = {
    datas: PropTypes.array
};

export default NewsMobile;
