import React, { useRef, useState, useEffect } from "react";
import PropTypes from "prop-types";
import { MediaSection, MediaDivWrapper, MediaParagraphs, MediaDisplay, PaginationStyled, DotWrapper, FirstLastWrapper, MediaPaginationWrapper } from "../../../style/MediaScreen";
import { Modal as MediaModal } from "antd";
import PostNew from "../Data/PostNew";
import PostPastNew from "../Data/PostPastNew";
import PostModal from "../Data/PostModal";
import { useTranslation } from "react-i18next";

const NewsSection = ({ onHeightChange, datas }) => {
    const sectionRef = useRef(null);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [postData, setPostData] = useState(null);
    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 4;
    const { t, i18n } = useTranslation();
    const currentLanguageCode = i18n.language;

    useEffect(() => {
        i18n.changeLanguage(currentLanguageCode);
    }, []);

    const openModal = (data) => {
        setIsModalOpen(true);
        setPostData(data);
    };

    const closeModal = () => {
        setIsModalOpen(false);
    };

    const handlePageChange = (page) => {
        setCurrentPage(page);
    };

    const startIndex = (currentPage - 1) * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;
    let dataLists;
    let datasWithoutFirstElement;
    if (datas) {
        datasWithoutFirstElement = datas.slice(1);
        dataLists = datasWithoutFirstElement.slice(startIndex, endIndex);
    } else {
        dataLists = [];
    }
    const itemRender = (_, type, originalElement) => {
        if (type === "page") {
            return <DotWrapper className="custom-pagination-dot"></DotWrapper>;
        }
        if (type === "prev") {
            return <FirstLastWrapper languagecode={currentLanguageCode}>{t("PREV")}</FirstLastWrapper>;
        }
        if (type === "next") {
            return <FirstLastWrapper languagecode={currentLanguageCode}>{t("NEXT")}</FirstLastWrapper>;
        }
        return originalElement;
    };

    useEffect(() => {
        const sectionHeight = sectionRef.current ? sectionRef.current.clientHeight : 0;
        onHeightChange((prevHeight) => prevHeight + sectionHeight);
    }, [onHeightChange]);

    return (
        <MediaSection id="news" justify={"center"} ref={sectionRef}>
            <MediaDivWrapper width={"1366px"} padding={"90px 82px"}>
                <MediaDisplay direction={"row"} height={"636px"} justify={"space-between"}>
                    {/* NEWS */}
                    <MediaDivWrapper width={"65%"} height={"100%"} padding={"0 10px 0 0"} position={"relative"}>
                        <MediaParagraphs languagecode={currentLanguageCode} fsize={"47px"} align={"left"} linehg={"47px"}>
                            {t("NEWS")}
                        </MediaParagraphs>
                        <MediaDivWrapper width={"100%"} padding={"32px 0 0 0 "}>
                            <PostNew data={datas ? datas[0] : null} openModal={openModal} />
                        </MediaDivWrapper>
                    </MediaDivWrapper>

                    {/* PAST NEWS */}
                    {dataLists && dataLists.length > 0 && (
                        <MediaDivWrapper width={"35%"} height={"100%"} position={"relative"}>
                            <MediaParagraphs languagecode={currentLanguageCode} fsize={"32px"} align={"left"} linehg={"32px"} padding={"20px 0 0 0"}>
                                {t("PAST NEWS")}
                            </MediaParagraphs>
                            <MediaDivWrapper width={"100%"} margin={"27px 0 0 0"}>
                                {dataLists.map((item) => (
                                    <PostPastNew key={item.id} data={item} openModal={openModal} />
                                ))}
                            </MediaDivWrapper>
                            {datasWithoutFirstElement && datasWithoutFirstElement.length > 0 && datasWithoutFirstElement.length > itemsPerPage && (
                                <MediaPaginationWrapper position={"absolute"} bottom={"-10px"} left={"0"} right={"0"}>
                                    <PaginationStyled className="custom-pagination" current={currentPage} onChange={handlePageChange} total={datasWithoutFirstElement.length} pageSize={itemsPerPage} showSizeChanger={false} itemRender={itemRender} />
                                </MediaPaginationWrapper>
                            )}
                        </MediaDivWrapper>
                    )}
                </MediaDisplay>
                <MediaModal open={isModalOpen} onCancel={closeModal} footer={null} width={"1366px"} padding={"10px"} zIndex={9999999999999} centered className="fullscreen-modal" closeIcon={true}>
                    <PostModal data={postData || {}} />
                </MediaModal>
            </MediaDivWrapper>
        </MediaSection>
    );
};

NewsSection.propTypes = {
    onHeightChange: PropTypes.func,
    datas: PropTypes.array
};

export default NewsSection;
