import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import parse from "html-react-parser";
import { MediaDivWrapper, MediaDisplay, MediaImage, MediaParagraphs, ParagraphTitleStyled, ParagraphDescriptionStyled, MediaReadMoreWrapper, MediaReadMore } from "../../../style/MediaScreen";

const ellipsTitle = {
    rows: 2,
    width: 100,
    expandable: false
};

const ellipsDescription = {
    rows: 3,
    expandable: false
};

const ellipsTitleMobile = {
    rows: 2,
    expandable: false
};

const ellipsDescriptionMobile = {
    rows: 2,
    expandable: false
};

const truncateText = (text, maxLength) => {
    if (text.length > maxLength) {
        return `${text.substring(0, maxLength)}...`;
    }
    return text;
};

const PostPastNew = ({ data, isMobile = false, openModal }) => {
    if (!data) {
        return null;
    }

    const { t, i18n } = useTranslation();
    const currentLanguageCode = i18n.language;
    useEffect(() => {
        i18n.changeLanguage(currentLanguageCode);
    }, []);

    const { imageUrl, title, postDated, summary } = data;

    if (isMobile) {
        return (
            <MediaDisplay direction={"row"} height={"100%"} margin={"0 0 10px 0"}>
                <MediaDivWrapper width={"50%"}>
                    <MediaImage src={`${process.env.REACT_APP_CMS}${imageUrl}`} width={"100%"} height={"100%"} fit={"cover"} onClick={() => openModal(data)} />
                </MediaDivWrapper>
                <MediaDivWrapper width={"50%"} padding={"0 0 0 5px"} position={"relative"}>
                    <MediaDisplay width={"100%"} direction={"row"}>
                        <MediaParagraphs languagecode={currentLanguageCode} font={"'Segoe UI', Helvetica"} fsize={"11px"} align={"left"} linehg={"15px"} fweight={"700"} wspace={""} cursor={"pointer"} onClick={() => openModal(data)}>
                            <ParagraphTitleStyled width={"100%"} height={"100%"} ellipsis={ellipsTitleMobile}>
                                {parse(title)}
                            </ParagraphTitleStyled>
                        </MediaParagraphs>
                    </MediaDisplay>
                    <MediaParagraphs color={"#2C53AF"} languagecode={currentLanguageCode} font={"'Segoe UI', Helvetica"} fsize={"11px"} fweight={"700"} align={"right"} linehg={"11px"} cursor={"pointer"} onClick={() => openModal(data)}>
                        {parse(postDated)}
                    </MediaParagraphs>
                    <MediaDivWrapper width={"100%"} height={"100%"} onClick={() => openModal(data)}>
                        <MediaParagraphs overflow={"hidden"} languagecode={currentLanguageCode} font={"'Segoe UI', Helvetica"} fsize={"11px"} align={"justify"} fweight={"400"} linehg={"normal"} wspace={""}>
                            <ParagraphDescriptionStyled width={"100%"} height={"100%"} ellipsis={ellipsDescriptionMobile}>
                                {parse(truncateText(summary, 70))}
                            </ParagraphDescriptionStyled>
                        </MediaParagraphs>
                    </MediaDivWrapper>
                    <MediaReadMoreWrapper width={"100%"} position={"absolute"} bottom={"0"} right={"0"} onClick={() => openModal(data)}>
                        <MediaDisplay justify={"end"}>
                            <MediaReadMore languagecode={currentLanguageCode}>{t("READ MORE")}</MediaReadMore>
                        </MediaDisplay>
                    </MediaReadMoreWrapper>
                </MediaDivWrapper>
            </MediaDisplay>
        );
    } else {
        return (
            <MediaDisplay direction={"row"} height={"126px"} margin={"0 0 10px 0"}>
                <MediaDivWrapper width={"50%"}>
                    <MediaImage src={`${process.env.REACT_APP_CMS}${imageUrl}`} width={"100%"} height={"100%"} fit={"cover"} onClick={() => openModal(data)} />
                </MediaDivWrapper>
                <MediaDivWrapper display={"flex"} fdirection={"column"} width={"50%"} height={"100%"} padding={"0 0 0 5px"} position={"relative"}>
                    <MediaDisplay width={"100%"} height={"auto"} direction={"row"}>
                        <MediaParagraphs languagecode={currentLanguageCode} font={"'Segoe UI', Helvetica"} fsize={"14px"} align={"left"} linehg={"14px"} fweight={"600"} wspace={""} cursor={"pointer"} onClick={() => openModal(data)}>
                            <ParagraphTitleStyled width={""} ellipsis={ellipsTitle}>
                                {parse(title)}
                            </ParagraphTitleStyled>
                        </MediaParagraphs>
                    </MediaDisplay>
                    <MediaParagraphs color={"#2C53AF"} languagecode={currentLanguageCode} font={"'Segoe UI', Helvetica"} fsize={"14px"} fweight={"600"} align={"right"} linehg={"14px"} cursor={"pointer"} onClick={() => openModal(data)}>
                        {parse(postDated)}
                    </MediaParagraphs>
                    <MediaDivWrapper width={"100%"} height={"auto"} onClick={() => openModal(data)}>
                        <MediaParagraphs overflow={"hidden"} languagecode={currentLanguageCode} font={"'Segoe UI', Helvetica"} fsize={"11px"} align={"left"} fweight={"400"} linehg={"normal"} wspace={""}>
                            <ParagraphDescriptionStyled ellipsis={ellipsDescription}>{parse(summary)}</ParagraphDescriptionStyled>
                        </MediaParagraphs>
                    </MediaDivWrapper>
                    <MediaReadMoreWrapper width={"100%"} height={"auto"} position={"absolute"} bottom={"2px"} left={"5%"}>
                        <MediaDisplay justify={"start"}>
                            <MediaReadMore languagecode={currentLanguageCode} onClick={() => openModal(data)}>
                                {t("READ MORE")}
                            </MediaReadMore>
                        </MediaDisplay>
                    </MediaReadMoreWrapper>
                </MediaDivWrapper>
            </MediaDisplay>
        );
    }
};

PostPastNew.propTypes = {
    openModal: PropTypes.func,
    isMobile: PropTypes.bool,
    data: PropTypes.object
};

export default PostPastNew;
